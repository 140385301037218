import LayoutWrapper from "../../../components/utility/layoutWrapper";
import Papersheet from "../../../components/utility/papersheet/papersheet.style";
import { FullColumn } from "../../../components/utility/rowColumn";
import IntlMessages from "../../../components/utility/intlMessages";
import { de } from "date-fns/locale";
import React, { useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
import { format } from "date-fns";
import { useEffect } from "react";
import { Radio } from "@material-ui/core";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useRef } from "react";
import { logigloUrl } from "../../../containers/Config/index";
import ReactQuill from "react-quill";
import ImageUpload from "../../LandingPageComponents/ImageUploadTest/ImageUpload";
import ImageGallery from "../../customHooks/fetchIndependentCareersImage";
import "react-quill/dist/quill.snow.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CFormTextarea,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CPopover,
  CTooltip,
  CToastHeader,
  CToastBody,
  CToast,
  CToaster,
} from "@coreui/react";
import "./index.css";

const CareersForm = () => {
  const history = useHistory();
  const location = useLocation();
  // const account_id = new URLSearchParams(location.search).get("account_id");

  const userAccount_id = localStorage.getItem("token");
  const tokenObject = JSON.parse(userAccount_id);
  const account_id = tokenObject.accountId;
  const account_id_number = parseInt(account_id, 10);

  const account_name = useSelector((state) => state.Dashboard.name);
  // console.log(account_name, "in_create_post_page");

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [company, setCompany] = useState("");
  const [source, setSource] = useState("");
  const [tags, setTags] = useState("");
  const [createCareersSuccessModal, setCreateCareersSuccessModal] = useState(false)
  const [image, setImage] = useState(null);


  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
};

const handleUpload = () => {
    const formData = new FormData();
    formData.append('image', image);

    fetch(`${logigloUrl}/careersImageUpload`, {
        method: 'POST',
        body: formData,
    })
        .then((response) => response.text())
        .then((result) => {
            console.log(result);
            alert('Image uploaded successfully!');
        })
        .catch((error) => {
            console.error('Error:', error);
            alert('Failed to upload image.');
        });
};


  const onClickCareersConfirmationModalClose = () => {
    setCreateCareersSuccessModal(false);

  };


  const onClickProceedCareerPost =  () => {
    history.push('/Careers/admin/modify')


   
    
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();


  //   const currentDate = new Date(); // Get the current date and time
  //   const timeZone = "Asia/Kolkata";

  //   const formattedDateForDatabase = format(
  //     currentDate,
  //     "yyyy-MM-dd HH:mm:ss",
  //     { timeZone }
  //   );


  //   // Replace newline characters with <br> tags
  // const formattedText = text.replace(/\n/g, '<br>');

 


  //   const formData = {
  //     title: title,
  //     description: formattedText,
  //     company: company,
  //     source: source,
  //     tags: tags,
  //     created_at: formattedDateForDatabase,
  //   };

  //   try {
  //     const response = await axios.post(`${logigloUrl}/createCareer`, formData);
  //     console.log("Career created successfully:", response.data);
  //     // Optionally redirect or perform other actions upon successful submission
  //     setTitle("");
  //     setText("");
  //     setCompany("");
  //     setSource("");
  //     setTags("");
  //     setCreateCareersSuccessModal(true);
      
  //     // history.push("/Careers"); // Redirect to careers page after successful submission
  //   } catch (error) {
  //     console.error("Error creating career:", error);
  //     // Handle error responses or display error messages
  //   }

    

   
  // };



//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Extract image tags using DOMParser or regular expressions
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(text, 'text/html');
//     const imgTags = doc.querySelectorAll('img');
//     const imgFiles = [];

//     for (let i = 0; i < imgTags.length; i++) {
//         const imgTag = imgTags[i];
//         const imgSrc = imgTag.getAttribute('src');

//         // Check if it's a base64 string (image uploaded through ReactQuill)
//         if (imgSrc && imgSrc.startsWith('data:image')) {
//             // Convert base64 string to File object
//             const base64Response = await fetch(imgSrc);
//             const blob = await base64Response.blob();
//             const file = new File([blob], `image_${i}.png`, { type: blob.type });
//             imgFiles.push(file);
//         }
//     }

//     // Upload each image file
//     const uploadedImageUrls = await Promise.all(imgFiles.map(async (file) => {
//         const formData = new FormData();
//         formData.append('image', file);

//         const response = await fetch(`${logigloUrl}/careersImageUpload`, {
//             method: 'POST',
//             body: formData,
//         });

//         if (response.ok) {
//             const result = await response.text();
//             console.log(result);

//             // Assuming the backend returns the URL of the uploaded image
//             const imageUrl = result; // Replace with actual response processing logic if needed
//             return imageUrl;
//         } else {
//             console.error('Failed to upload image.');
//             alert('Failed to upload image.');
//             return null;
//         }
//     }));

//     // Replace image src in text with uploaded URLs
//     uploadedImageUrls.forEach((url, index) => {
//         if (url) {
//             const imgTag = imgTags[index];
//             imgTag.setAttribute('src', url);
//         }
//     });

//     // Replace newline characters with <br> tags and convert to HTML string
//     const updatedText = doc.body.innerHTML;

//     const currentDate = new Date();
//     const timeZone = "Asia/Kolkata";

//     const formattedDateForDatabase = format(
//         currentDate,
//         "yyyy-MM-dd HH:mm:ss",
//         { timeZone }
//     );

//     const formData = {
//         title: title,
//         description: updatedText,
//         company: company,
//         source: source,
//         tags: tags,
//         created_at: formattedDateForDatabase,
//     };

//     try {
//         const response = await axios.post(`${logigloUrl}/createCareer`, formData);
//         console.log("Career created successfully:", response.data);

//         // Reset form and open modal
//         setTitle("");
//         setText("");
//         setCompany("");
//         setSource("");
//         setCreateCareersSuccessModal(true);
//         setTags("");
//     } catch (error) {
//         console.error("Error creating career:", error);
//     }
// };

const handleSubmit = async (e) => {
  e.preventDefault();

  // Extract image tags using DOMParser or regular expressions
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  const imgTags = doc.querySelectorAll('img');
  const imgFiles = [];

  for (let i = 0; i < imgTags.length; i++) {
      const imgTag = imgTags[i];
      const imgSrc = imgTag.getAttribute('src');

      if (imgSrc && imgSrc.startsWith('data:image')) {
          const base64Response = await fetch(imgSrc);
          const blob = await base64Response.blob();
          const file = new File([blob], `image_${i}.png`, { type: blob.type });
          imgFiles.push(file);
      }
  }

    // Remove image tags from description
    imgTags.forEach(imgTag => imgTag.remove());

  // Replace newline characters with <br> tags and convert to HTML string
  const updatedText = doc.body.innerHTML;

  const currentDate = new Date();
  const timeZone = "Asia/Kolkata";

  const formattedDateForDatabase = format(
      currentDate,
      "yyyy-MM-dd HH:mm:ss",
      { timeZone }
  );

  // Create form data for submission
  const formData = new FormData();
  formData.append('title', title);
  formData.append('description', updatedText);
  formData.append('company', company);
  formData.append('source', source);
  formData.append('tags', tags);
  formData.append('created_at', formattedDateForDatabase);

  // Append image files to formData
  imgFiles.forEach((file) => {
      formData.append('images', file);
  });

  try {
      const response = await axios.post(`${logigloUrl}/createCareer`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      });
      console.log("Career created successfully:", response.data);

      // Reset form and open modal
      setTitle("");
      setText("");
      setCompany("");
      setSource("");
      setCreateCareersSuccessModal(true);
      setTags("");
  } catch (error) {
      console.error("Error creating career:", error);
  }
};



const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image",],  // Add "pdf" here
      ["clean"],
    ],
   
  },
};

  const formats = [
    "header",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "pdf"
  ];
  


  const onClickCancelBtn = () => {
    window.open("/");
  };

  return (
    <LayoutWrapper>
      <FullColumn>
        <div className="career-page-form-con">
         
        {!createCareersSuccessModal ? (
          <form className="form-inputs-con" onSubmit={handleSubmit}>
           

            <div md={8}>
              <CFormLabel
                htmlFor="inputTitle"
                style={{ color: "red" }}
              >
                Title
              </CFormLabel>
              <CFormInput
                type="text"
                id="inputTitle"
                value={title}
                className="title-input"
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>

            

            <div md={8}>
              <CFormLabel htmlFor="postDescription" style={{ color: "red" }}>
                Description
              </CFormLabel>

              <div className="text-editor">
                  <ReactQuill
                   type="text"
                    id="postDescription"
                    value={text}
                    onChange={(content) => setText(content)}
                    theme="snow"
                    rows={8}
                    modules={modules}
                    formats={formats}
                    required
                    className="ReactQuill-styles"
                    // className="ReactQuill-custom"
                    style={{ borderRadius: '5px' }}
                  />
                </div>
            </div>

           

            <div md={8}>
              <CFormLabel
                htmlFor="Company"
                style={{ color: "red" }}
              >
                Company
              </CFormLabel>
              <CFormInput
                type="text"
                id="Company"
                className="title-input"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                required
              />
            </div>

            {/* <CCol md={12} className="career-page-input-container-styels">
              <div className="label-con">
                <CFormLabel
                  htmlFor="inputTitle"
                  className="label-styles"
                  style={{ color: "black" }}
                >
                  Source
                </CFormLabel>
              </div>
              <CFormInput
                type="text"
                id="inputTitle"
                // placeholder="Title"
                className="title-input"
                value={source}
                onChange={(e) => setSource(e.target.value)}
                
              />
            </CCol> */}

            <div md={8}>
              <CFormLabel htmlFor="Source">Source</CFormLabel>
              <CFormInput
                type="text"
                id="Source"
                // placeholder="name@example.com"
                className="title-input"
                value={source}
                onChange={(e) => setSource(e.target.value)}
              />
            </div>

            {/* <div>
            <input type="file" onChange={handleImageChange} />
            <button onClick={handleUpload}>Upload</button>
        </div> */}
          

            <div md={8}>
              <CFormLabel htmlFor="Tags">Tags</CFormLabel>

              <CFormTextarea
                id="Tags"
                type="text"
                rows={3}
                placeholder="Keywords"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                // className="title-input"
                className="description-input"
              ></CFormTextarea>
            </div>

            <CCol md={6} style={{ marginRight: "10px", marginTop: "10px" }}>
              <CButton
                className="btn btn-primary"
                color="primary"
                type="submit"
                style={{ marginRight: "20px", backgroundColor: '#155CA2' }}
                // onClick={onClickSubmitCareerPost} 
              >
                Submit
              </CButton>

              <CButton
                className="btn btn-light"
                color="primary"
                onClick={onClickCancelBtn}
              >
                Cancel
              </CButton>
            </CCol>



          
          </form>
           ) : null } 

          {createCareersSuccessModal ? (
                      <CModal
                        className="show d-block position-static"
                        backdrop={false}
                        keyboard={false}
                        portal={false}
                        visible
                      >
                        <CModalHeader
                          onClick={onClickCareersConfirmationModalClose}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "20px",
                          }}
                        >
                          <CModalTitle htmlFor="enterQuotePrice">
                          Job posted successfully<span style={{color: 'green'}}> </span>
                          </CModalTitle>
                        </CModalHeader>

                      

                        {/* <div style={{ width: "80%", alignSelf: "center" }}>
                          <p>Job posted successfully<span style={{color: 'green'}}> </span></p>
                        </div> */}


                        <CModalFooter style={{ width: "100%", border: "0px" }}>
                          <CButton
                            color="secondary"
                              onClick={onClickCareersConfirmationModalClose}
                          >
                            Create New job
                          </CButton>
                         
                          <CButton
                            color="success"
                            style={{backgroundColor: '#155CA2'}}
                              onClick={onClickProceedCareerPost}
                          >
                            View Jobs 
                          </CButton>
                        </CModalFooter>
                      </CModal>
                    ) : null}



          
        </div>
      </FullColumn>
    </LayoutWrapper>
  );
};

export default CareersForm;