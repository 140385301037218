
import React, { useState } from "react";
import "./RequestButton.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logigloUrl } from "../../../Config";

const RequestButton = () => {
  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    mobile_number: "",
  });

  const openRequestForm = () => {
    setShowForm(true);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const validateForm = () => {
    let validationErrors = {};
    const fullName = formData.full_name.trim();
    const email = formData.email.trim();
    const mobileNumber = formData.mobile_number.trim();

    // Validate Full Name
    if (!/^[a-zA-Z\s]+$/.test(fullName)) {
      validationErrors.full_name =
        "Full Name must contain only alphabets. Numbers are not allowed.";
    }

    // Validate Email
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      validationErrors.email = "Enter a valid email address.";
    }

    // Validate Mobile Number
    if (!/^\d+$/.test(mobileNumber)) {
      validationErrors.mobile_number = "Mobile Number must contain only numbers.";
    }

    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      await axios.post(
        `${logigloUrl}/studentCourseRequest`,
        formData
      );
      toast.success("Submitted Successfully!");
      setShowForm(false);
    } catch (error) {
      console.error("Error submitting request:", error);
    }
  };

  return (
    <div>
      <div>
        <button className="requestButton" onClick={openRequestForm}>
          Request for connect
        </button>
      </div>

      {showForm && (
        <div className="Request-btn-form">
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="full_name">Full Name:</label>
              <input
                type="text"
                id="full_name"
                value={formData.full_name}
                required
                onChange={handleInputChange}
              />
              {errors.full_name && (
                <span className="request-form-error">{errors.full_name}</span>
              )}
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                required
                onChange={handleInputChange}
              />
              {errors.email && <span className="request-form-error">{errors.email}</span>}
            </div>
            <div>
              <label htmlFor="mobile_number">Mobile Number:</label>
              <input
                type="tel"
                id="mobile_number"
                value={formData.mobile_number}
                required
                onChange={handleInputChange}
              />
              {errors.mobile_number && (
                <span className="request-form-error">{errors.mobile_number}</span>
              )}
            </div>
            <button type="submit">Submit</button>
            <button type="button" onClick={() => setShowForm(false)}>
              Cancel
            </button>
          </form>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default RequestButton;
