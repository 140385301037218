import React, { useState } from 'react';
 import './CourseCurriculumAccordion.css'

const CourseCurriculumAccordion = ({ course }) => {
    console.log("Course",course)
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="course-accordion-item">
            <p className="course-accordion-header" onClick={toggleAccordion}>
                {course?.section_title}
                <span className={`course-accordion-arrow ${isOpen ? 'open' : ''}`}>
                    {isOpen ? '▲' : '▼'}
                </span>
            </p>
            {isOpen && (
                <div className="course-accordion-content">
                    <ul>
                        {course.videos.map((video, index) => (
                            <li key={index} className="course-video-item">
                                <span className="course-video-title">{video.video_title}</span>
                                <a 
                                    className="course-video-preview" 
                                    href={video.video_link} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    Preview
                                </a>
                                <span className='course-video-duration'>{video.duration_in_minutes}</span>

                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CourseCurriculumAccordion;
