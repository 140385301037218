import React, { useState, useEffect } from "react";
import axios from "axios";
import likeIconFill from "../../assests/likeIconFill.svg";
import likeIcon from "../../assests/likeIcon.svg";
import dislikeIcon from "../../assests/dislikeIcon.svg";
import dislikeIconFill from "../../assests/dislikeIconFill.svg";
import { logigloUrl } from "../Config/index";
import { BiUpvote } from "react-icons/bi";
import { BiSolidUpvote } from "react-icons/bi";
import { BiDownvote } from "react-icons/bi";
import { BiSolidDownvote } from "react-icons/bi";
import { LuArrowBigUp } from "react-icons/lu";
import "./index.css";

const LikesDislikes = ({ postId, accountId }) => {
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [userLiked, setUserLiked] = useState(false); // Track if the user liked the post
  const [userDisliked, setUserDisliked] = useState(false); // Track if the user disliked the post

  useEffect(() => {
    fetchCounts();
  }, [postId]);

  const fetchCounts = () => {
    axios
      .get(`${logigloUrl}/getUserReactionsForPost/${postId}`, {
        params: { accountId },
      })
      .then((response) => {
        console.log("Fetched counts:", response.data);
        setLikes(response.data.likes || 0);
        setDislikes(response.data.dislikes || 0);
        setUserLiked(!!response.data.userLiked);
        setUserDisliked(!!response.data.userDisliked);
      })
      .catch((error) => {
        console.error("Error fetching reactions:", error);
      });
  };

  const handleLike = () => {
    console.log(userLiked, "alreadyliked");
    if (userLiked) {
      // console.log("")
      // User wants to remove their like
      axios
        .post(`${logigloUrl}/removeLike/${postId}`, { accountId })
        .then(() => {
          setUserLiked(false);
          fetchCounts();
        })
        .catch((error) => console.error("Error removing like:", error));
    } else {
      // User wants to like the post
      axios
        .post(`${logigloUrl}/likePost/${postId}`, { accountId })
        .then(() => {
          setUserLiked(true);
          fetchCounts();
          // Remove dislike if present
          if (userDisliked) {
            axios
              .post(`${logigloUrl}/removeDislike/${postId}`, { accountId })
              .then(() => {
                setUserDisliked(false);
                fetchCounts();
              })
              .catch((error) =>
                console.error("Error removing dislike:", error)
              );
          }
        })
        .catch((error) => console.error("Error liking post:", error));
    }
  };

  const handleDislike = () => {
    if (userDisliked) {
      // User wants to remove their dislike
      axios
        .post(`${logigloUrl}/removeDislike/${postId}`, { accountId })
        .then(() => {
          setUserDisliked(false);
          fetchCounts();
        })
        .catch((error) => console.error("Error removing dislike:", error));
    } else {
      // User wants to dislike the post
      axios
        .post(`${logigloUrl}/dislikePost/${postId}`, { accountId })
        .then(() => {
          setUserDisliked(true);
          fetchCounts();
          // Remove like if present
          if (userLiked) {
            axios
              .post(`${logigloUrl}/removeLike/${postId}`, { accountId })
              .then(() => {
                setUserLiked(false);
                fetchCounts();
              })
              .catch((error) => console.error("Error removing like:", error));
          }
        })
        .catch((error) => console.error("Error disliking post:", error));
    }
  };

  return (
    <div className="user-like-dislike-response-container" style={{justifyContent: 'space-between', marginLeft: '-7px'}}>
      {/* <span style={{ marginRight: "15px", }} className="like-dislike-icon-count-field">
        <img
          onClick={handleLike}
          width="18"
          height="18"
          src={userLiked ? likeIconFill : likeIcon }
          alt="Like Icon"
          style={{cursor: 'pointer', paddingRight: '1px', }}
        />
        
        
        {likes > 0 && likes}
      </span>


      <span className="like-dislike-icon-count-field">
        <img
          onClick={handleDislike}
          width="18"
          height="18"
          src={userDisliked ? dislikeIconFill : dislikeIcon}
          alt="Dislike Icon"
          style={{cursor: 'pointer', paddingRight: '1px'}}
        />
        {dislikes > 0 && dislikes}
      </span> */}

      <span onClick={handleLike} style={{ cursor: "pointer" }}>
        {userLiked ? (
          <BiSolidUpvote
            style={{ width: "30px", height: "20px", color: "Green" }}
          />
        ) : (
          <BiUpvote style={{ width: "30px", height: "20px" }} />
        )}
        <span style={{marginLeft: '-5px'}}>{likes > 0 && likes}</span>
      </span>

      <span onClick={handleDislike} style={{ cursor: "pointer" }}>
        {userDisliked ? (
          <BiSolidDownvote
            style={{ width: "30px", height: "20px", color: "red" }}
          />
        ) : (
          <BiDownvote style={{ width: "30px", height: "20px" }} />
        )}
        <span style={{marginLeft: '-5px'}}>{dislikes > 0 && dislikes}</span>
      </span>
    </div>
  );
};

export default LikesDislikes;
