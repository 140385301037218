import React from 'react';
import './CourseCurriculum.css'
import CourseCurriculumAccordion from './CourseCurriculumAccordion';

const CourseCurriculumMainAccordion = ({item}) => {

    return (
        <>
        <h4>Course Curriculum</h4>
        <div className="course-curriculum-accordion">
            
            {
                item?.sections?.map((item,i)=>(
                    <CourseCurriculumAccordion key={i} course={item}/>
                ))
            }
        </div>
        </>
    );
};

export default CourseCurriculumMainAccordion;
