import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import SearchFavoriteIcon from "../../assests/search-favorite-1";
import LayoutWrapper from "../../components/utility/layoutWrapper";
import { FullColumn } from "../../components/utility/rowColumn";
// import Navbar from "../NavbarOne/NavbarOne";
import Navbar from "../Navbar";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Advertisement from "../advertisement";
import Footer from "../LandingPageComponents/footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, Redirect, useHistory } from "react-router-dom";
import { logigloUrl } from "../Config";
import UserStatistics from "../userStatistics";
import { enUS } from "date-fns/locale";
import CustomIcons from "../../assests/messages";
import axios from "axios";
import "./index.css";
import ReactGA from "react-ga";
import { CCard, CCardBody, CCardTitle, CCardFooter } from "@coreui/react";
import { utcToZonedTime } from "date-fns-tz";
import {
  parseISO,
  format,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from "date-fns";

// Forum Dashboard Without Login
const ForumDashboard = () => {
  const [categoriesDetails, setCategoriesDetails] = useState([]);

  const history = useHistory();
  // Initialize Google Analytics
  ReactGA.initialize("G-PTE475PH2N");
  // Track page view
  ReactGA.pageview(window.location.pathname + window.location.search);
  const dispatch = useDispatch();
  const [addsData, setAddsData] = useState([]);
  const [activeExperts, setActiveExperts] = useState([]);
  const [userCounts, setUserCounts] = useState({
    totalUserCount: 0,
    onlineUserCount: 0,
  });

  // advertisements API
  useEffect(() => {
    // Fetch data from the backend
    axios
      .get(`${logigloUrl}/advertisementData`)
      .then((response) => {
        setAddsData(response.data);
        // console.log(response.data, "adds");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${logigloUrl}/forumCategoriesWithAllStats`).then((response) => {
      setCategoriesDetails(response.data);
    });
  }, []);

  const [selectedOptions, setSelectedOptions] = useState({
    transport_mode: null,
    postType: null,
    service: null,
    postOrigin: null,
    shipment: null,
  });

  const handleOptionChange = (categoryName, selectedOption) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [categoryName]: selectedOption,
    }));
  };

  const handleClick = (category, option) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [category]: option,
    }));
    history.push(`/forumDashboard/category/${category}/${option}`);
    // console.log(`Selected ${category}:`, option);
  };

  const getTimeDisplayText = (postCreatedAt) => {
    const createdTime = parseISO(postCreatedAt);
    const indianTimeZone = "Asia/Kolkata"; // India time zone
    const currentTime = new Date();

    // Convert createdTime to Indian time zone
    const createdTimeInIndianTimeZone = utcToZonedTime(
      createdTime,
      indianTimeZone
    );

    const timeDifferenceInSeconds = differenceInSeconds(
      currentTime,
      createdTimeInIndianTimeZone
    );
    const timeDifferenceInMinutes = differenceInMinutes(
      currentTime,
      createdTimeInIndianTimeZone
    );
    const timeDifferenceInHours = differenceInHours(
      currentTime,
      createdTimeInIndianTimeZone
    );
    const timeDifferenceInDays = differenceInDays(
      currentTime,
      createdTimeInIndianTimeZone
    );

    if (timeDifferenceInSeconds < 60) {
      // If post is created within the last minute, display "X seconds ago"
      return `${timeDifferenceInSeconds} second${
        timeDifferenceInSeconds !== 1 ? "s" : ""
      } ago`;
    } else if (timeDifferenceInMinutes < 60) {
      // If post is created within the last hour, display "X minutes ago"
      return `${timeDifferenceInMinutes} minute${
        timeDifferenceInMinutes !== 1 ? "s" : ""
      } ago`;
    } else if (timeDifferenceInHours < 24) {
      // If post is created within the last day, display "X hours and Y minutes ago"
      const remainingMinutes =
        timeDifferenceInMinutes - timeDifferenceInHours * 60;
      return `${timeDifferenceInHours} hour${
        timeDifferenceInHours !== 1 ? "s" : ""
      } ${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""} ago`;
    } else {
      // If post is created more than 1 day ago, display formatted date and time
      return format(createdTimeInIndianTimeZone, "MM/dd/yyyy, hh:mm:ss a");
    }
  };

  // const onClickRecentPost = (category) => {
  //   console.log(category, "category")
  // }

  const post_title_to_display = 120; // Number of letters to display
  const getFirstNLettersForPostTitle = (text, n) => {
    const firstNLetters = text.slice(0, n); // Extract the first N letters
    return firstNLetters + (text.length > n ? "..." : ""); // Add "..." if text is longer than N letters
  };

  // Calculate the sum of login_count values
  const totalLoginCount = activeExperts.reduce(
    (sum, expert) => sum + expert.login_count,
    0
  );
  // console.log(totalLoginCount);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Logiglo Forum</title>
        <link rel="canonical" href="https://logiglo.com/forum" />
        <meta
          property="og:title"
          content="Gateway to the global world of logistics and supply chain management. Discover opportunities, insights, and innovations that drive the industry forward, helping you grow your business and career with cutting-edge solutions and expert advice."
        />
        <meta
          name="description"
          property="og:description"
          content="Forum Description"
        />
        <meta
          property="og:image"
          content="https://opengraph.b-cdn.net/production/images/f8ba140a-981c-4421-a09e-ebae95b0534f.png?token=FXByuwPXqDXbCAr2fdnElBC4FiDHbW6vOC8r-8b_Kg0&height=675&width=1200&expires=33259546757"
        />
        <meta property="og:url" content="https://logiglo.com/forum" />
        <meta property="og:type" content="website" />
      </Helmet>
      <LayoutWrapper>
        <Navbar />
        <FullColumn>
          <div className="forum-dashboard-outer-con">
            <div className="forum-for-all-dashboard-inner-con">
              <div className="forum-content-con">
                {/* left side adds container */}
                <div className="forum-home-left-adds-con">
                  
                <div className="forum-landing-page-ads-contaner">
                  <Advertisement module="Forum" type="left"  />
                </div>

                  {/* {addsData.map((item) => {
                    if (item.type === "left" && item.render) {
                      return (
                        <Link
                          to={item.navigation_url}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <div key={item.id} className="forum-left-adds-con">
                            <img
                              src={item.image_url}
                              className="ad-image-styles"
                            />
                            <h5>{item.title}</h5>
                            <p>{item.text}</p>
                          </div>
                        </Link>
                      );
                    }
                    return null;
                  })} */}

                  <div className="forum-left-nav-con">
                    <Link to="/ContactUs" style={{ textDecoration: "none" }}>
                      <div className="each-icon-con-in-left-section">
                        <CustomIcons
                          base64={
                            "PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTAwMDIgNy41NTk5OUM5LjIxMDAyIDMuOTU5OTkgMTEuMDYgMi40ODk5OSAxNS4xMSAyLjQ4OTk5SDE1LjI0QzE5LjcxIDIuNDg5OTkgMjEuNSA0LjI3OTk5IDIxLjUgOC43NDk5OVYxNS4yN0MyMS41IDE5Ljc0IDE5LjcxIDIxLjUzIDE1LjI0IDIxLjUzSDE1LjExQzExLjA5IDIxLjUzIDkuMjQwMDIgMjAuMDggOC45MTAwMiAxNi41NCIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNSAxMkgzLjYyIiBzdHJva2U9IiMyOTJEMzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTUuODUgOC42NDk5TDIuNSAxMS45OTk5TDUuODUgMTUuMzQ5OSIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
                          }
                        />
                        <p className="left-text">Advertise</p>
                      </div>
                    </Link>

                    <Link to="/ContactUs" style={{ textDecoration: "none" }}>
                      <div className="each-icon-con-in-left-section">
                        <CustomIcons
                          base64={
                            "PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTAwMDIgNy41NTk5OUM5LjIxMDAyIDMuOTU5OTkgMTEuMDYgMi40ODk5OSAxNS4xMSAyLjQ4OTk5SDE1LjI0QzE5LjcxIDIuNDg5OTkgMjEuNSA0LjI3OTk5IDIxLjUgOC43NDk5OVYxNS4yN0MyMS41IDE5Ljc0IDE5LjcxIDIxLjUzIDE1LjI0IDIxLjUzSDE1LjExQzExLjA5IDIxLjUzIDkuMjQwMDIgMjAuMDggOC45MTAwMiAxNi41NCIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNSAxMkgzLjYyIiBzdHJva2U9IiMyOTJEMzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTUuODUgOC42NDk5TDIuNSAxMS45OTk5TDUuODUgMTUuMzQ5OSIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
                          }
                        />
                        <p className="left-text">Help</p>
                      </div>
                    </Link>

                    <Link
                      className="each-icon-con-in-left-section"
                      to="/privacy"
                    >
                      <CustomIcons
                        base64={
                          "PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTAwMDIgNy41NTk5OUM5LjIxMDAyIDMuOTU5OTkgMTEuMDYgMi40ODk5OSAxNS4xMSAyLjQ4OTk5SDE1LjI0QzE5LjcxIDIuNDg5OTkgMjEuNSA0LjI3OTk5IDIxLjUgOC43NDk5OVYxNS4yN0MyMS41IDE5Ljc0IDE5LjcxIDIxLjUzIDE1LjI0IDIxLjUzSDE1LjExQzExLjA5IDIxLjUzIDkuMjQwMDIgMjAuMDggOC45MTAwMiAxNi41NCIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNSAxMkgzLjYyIiBzdHJva2U9IiMyOTJEMzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTUuODUgOC42NDk5TDIuNSAxMS45OTk5TDUuODUgMTUuMzQ5OSIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
                        }
                      />
                      <p className="left-text">Content Policy</p>
                    </Link>

                    <Link
                      className="each-icon-con-in-left-section"
                      to="/privacy"
                    >
                      <CustomIcons
                        base64={
                          "PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTAwMDIgNy41NTk5OUM5LjIxMDAyIDMuOTU5OTkgMTEuMDYgMi40ODk5OSAxNS4xMSAyLjQ4OTk5SDE1LjI0QzE5LjcxIDIuNDg5OTkgMjEuNSA0LjI3OTk5IDIxLjUgOC43NDk5OVYxNS4yN0MyMS41IDE5Ljc0IDE5LjcxIDIxLjUzIDE1LjI0IDIxLjUzSDE1LjExQzExLjA5IDIxLjUzIDkuMjQwMDIgMjAuMDggOC45MTAwMiAxNi41NCIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNSAxMkgzLjYyIiBzdHJva2U9IiMyOTJEMzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTUuODUgOC42NDk5TDIuNSAxMS45OTk5TDUuODUgMTUuMzQ5OSIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
                        }
                      />
                      <p className="left-text">Privacy Policy</p>
                    </Link>
                  </div>
                </div>

                <div className="forum-landing-page-categories-main-comp">
                  {/* user Stats */}
                  <UserStatistics />

                  <div className="discussion-board">
                    <h4 className="category-header">
                      Posts for Different Categories
                    </h4>
                    {categoriesDetails.map((category, displayIndex) => (
                      <div
                        key={category.id}
                        className={`category-container ${
                          displayIndex % 2 === 0
                            ? "each-category-container-odd"
                            : "each-category-container-even"
                        }`}
                      >
                        <div
                          className="category-name-con"
                          style={{ backgroundColor: "#f4d99f" }}
                        >
                          <h4 className="category-name">{category.name}</h4>
                          <div className="each-category-summary-container">
                            {" "}
                            <strong>
                              {" "}
                              {category.number_of_posts} Posts |{" "}
                              {category.number_of_replies} Replies{" "}
                            </strong>
                          </div>
                        </div>

                        {/* Render subcategories */}
                        {category.options.map(
                          (option) =>
                            option.enabled && (
                              <div className="sub-cat-main-con">
                                <div key={option.id} className="subcategory">
                                  <h6
                                    className="subcategory-label-text"
                                    onClick={() =>
                                      handleClick(
                                        category.name === "Quote Enquiry"
                                          ? "transport_mode"
                                          : category.name === "General Enquiry"
                                          ? "transport_mode"
                                          : category.name ===
                                            "General Discussion"
                                          ? "general_discussion"
                                          : category.name ===
                                            "Supply Chain Management"
                                          ? "supply_chain_management"
                                          : category.name ===
                                            "Industry Verticals"
                                          ? "Industry_verticals"
                                          : category.name ===
                                            "Technologies & Innovations"
                                          ? "technologies_Innovations"
                                          : category.name ===
                                            "Sustainability & CSR"
                                          ? "sustainability_CSR"
                                          : category.name ===
                                            "Regulations & Compliance"
                                          ? "regulations_compliance"
                                          : category.name ===
                                            "Professional Development"
                                          ? "professional_development"
                                          : category.name,
                                        option.label
                                      )
                                    }
                                  >
                                    {option.label}
                                  </h6>

                                  {/* Render posts under subcategory */}
                                  {option.posts.length > 0 ? (
                                    <div className="sub-cat-post-info">
                                      {option.posts.map((post, postIndex) => (
                                        <div key={postIndex}>
                                          <p className="category-summary-text">
                                            Recent Post:
                                          </p>
                                          <p
                                            className="category-summary-text"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleClick(
                                                category.name ===
                                                  "Quote Enquiry"
                                                  ? "transport_mode"
                                                  : category.name ===
                                                    "General Enquiry"
                                                  ? "transport_mode"
                                                  : category.name ===
                                                    "General Discussion"
                                                  ? "general_discussion"
                                                  : category.name ===
                                                    "Supply Chain Management"
                                                  ? "supply_chain_management"
                                                  : category.name ===
                                                    "Industry Verticals"
                                                  ? "Industry_verticals"
                                                  : category.name ===
                                                    "Technologies & Innovations"
                                                  ? "technologies_Innovations"
                                                  : category.name ===
                                                    "Sustainability & CSR"
                                                  ? "sustainability_CSR"
                                                  : category.name ===
                                                    "Regulations & Compliance"
                                                  ? "regulations_compliance"
                                                  : category.name ===
                                                    "Professional Development"
                                                  ? "professional_development"
                                                  : category.name,
                                                option.label
                                              )
                                            }
                                          >
                                            {" "}
                                            {getFirstNLettersForPostTitle(
                                              post.title,
                                              post_title_to_display
                                            )}{" "}
                                          </p>
                                          <div className="created-by-details">
                                            <p>Posted by: {post.created_by}</p>
                                            <p className="posted-on-text-styles">
                                              {getTimeDisplayText(
                                                post.created_at
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <div className="no-post-text">
                                      No Posts Available in this Sub Category
                                    </div>
                                  )}
                                </div>

                                <hr style={{ margin: "0px" }} />
                              </div>
                            )
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/* right side adds container */}
                <div className="forum-home-right-adds-con">
                  


                <div className="forum-landing-page-ads-contaner">
                  <Advertisement module="Forum" type="right"  />
                </div>

                  {/* {addsData.map((item) => {
                    if (item.type === "right" && item.render) {
                      return (
                        <Link
                          to={item.navigation_url}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <div key={item.id} className="forum-right-adds-con">
                            <img
                              src={item.image_url}
                              className="ad-image-styles"
                            />
                            <h5>{item.title}</h5>
                            <p>{item.text}</p>
                          </div>
                        </Link>
                      );
                    }
                    return null;
                  })} */}

                  <div style={{ marginTop: "20px" }}>
                    <h5>Priority Questions</h5>
                    <p className="forum-adds-text">
                      Need supplier of fleet to carry 1K Tons of perishable
                      goods priority
                    </p>
                    <p className="forum-adds-text">
                      Urgent requirement of Pharmaceutical goods logistics
                      vendor
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </FullColumn>
      </LayoutWrapper>
    </HelmetProvider>
  );
};

export default ForumDashboard;
