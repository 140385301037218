import React, { useState, useEffect, useMemo } from "react";
import LayoutWrapper from "../../components/utility/layoutWrapper";
import SearchFavoriteIcon from "../../assests/search-favorite-1";
import Papersheet from "../../components/utility/papersheet";
import { FullColumn } from "../../components/utility/rowColumn";
import IntlMessages from "../../components/utility/intlMessages";
import LikesDislikes from "../LikeDislikeComponent";
import { Link, useLocation, Redirect, useHistory } from "react-router-dom";
import { utcToZonedTime } from "date-fns-tz";
import {
  parseISO,
  format,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from "date-fns";
import axios from "axios";
import { logigloUrl } from "../Config/index";
import TopbarSearch from "../Topbar/index";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
// import { differenceInHours, formatDistanceToNow, parseISO } from "date-fns";
// import { format } from "date-fns";
// import { enUS } from "date-fns/locale";
import {
  CButton,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CButtonGroup,
  CButtonToolbar,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CWidgetStatsF,
  CFormCheck,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CCardTitle,
  CCardFooter,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilArrowRight,
  cilBasket,
  cilBell,
  cilChartPie,
  cilMoon,
  cilLaptop,
  cilPeople,
  cilSettings,
  cilSpeech,
  cilSpeedometer,
  cilUser,
  cilUserFollow,
} from "@coreui/icons";
import "./index.css";
import Navbar from "../Navbar";
import Categorizer from "../Categorizer";
import Categories from "../Categorizer";
// import { FiEdit } from "react-icons/fi";


const UserStatistics = () => {
  const [activeUsers, setActiveUsers] = useState(null);
  const [totalPosts, setTotalPosts] = useState(null);
  const[totalusers,setTotalusers]=useState(null);
  const [onlineUserCount, setOnlineUserCount] = useState(null);



useEffect(() => {
  // Fetch active users from backend
  const fetchActiveUsers = async () => {
   try {
      const response = await axios.get(`${logigloUrl}/analytics`);
      const { activeUsers } = response.data;
      setActiveUsers(activeUsers);
      // console.log(response.data, "analytics")
   } catch (error) {
      console.error('Error fetching active users:', error);
   }
  };

  fetchActiveUsers();
}, []);


//Fetching TotalUsers from the backend
useEffect(() => {
  const fetchData = async () => {
   try {
      const response = await fetch(`${logigloUrl}/userCounts`);
      // console.log("Total users API Response:", response);
      const data = await response.json();
      setTotalusers(data.totalUserCount);
   } catch (error) {
      console.error("Error fetching data:", error);
   }
  };

  fetchData();
}, []);



useEffect(() => {
  // Fetch total posts from API
  const fetchTotalPosts = async () => {
   try {
      const response = await axios.get(`${logigloUrl}/getAllPostsData`);
      // console.log('Total post API response:', response.data);
      const { totalCount } = response.data;
      setTotalPosts(totalCount);
   } catch (error) {
      console.error('Error fetching total posts:', error);
   }
  };

  fetchTotalPosts();
}, []);

useEffect(() => {
  const fetchData = async () => {
   try {
      const response = await fetch(`${logigloUrl}/userCounts`);
      // console.log("Total users API Response:", response);
      const data = await response.json();
      setOnlineUserCount(data.onlineUserCount);
   } catch (error) {
      console.error("Error fetching data:", error);
   }
  };

  fetchData();
}, []);
 

  return (
   
    <div className="outer-container">
    <div className="stat-card">
       <p className="stat-label">Total Members</p>
       <p className="stat-value">
        {/* <p className="stat-value">977</p> */}
       {totalusers !== null ? totalusers : 'Loading...'}
       </p>
    </div>
    <div className="stat-card">
       <p className="stat-label">Guest Users</p>
        {/* <p className="stat-value">354</p> */}
       <p className="stat-value">
        {activeUsers !== null ? activeUsers : 'Loading...'}
       </p>
    </div>
    <div className="stat-card">
    <p className="stat-label"> Users Online</p>
    <p className="stat-value">
       {onlineUserCount !== null ? onlineUserCount : "Loading..."}
    </p>
   </div>
    <div className="stat-card">
       <p className="stat-label">Total Posts</p>
       {/* <p className="stat-value">4977</p> */}
       <p className="stat-value">
        {totalPosts !== null ? totalPosts : 'Loading...'}
       </p>
    </div>
   </div>
);
};

export default UserStatistics;
