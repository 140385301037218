import React, { useState, useEffect } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logigloUrl } from "../../../src/containers/Config";
import axios from "axios";
import { CFormCheck, CFormLabel } from "@coreui/react";
import UserStatistics from "../userStatistics";
import { utcToZonedTime } from "date-fns-tz";
import {
  parseISO,
  format,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from "date-fns";
import "./index.css";
import { colors } from "@material-ui/core";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesData, setcategoriesData] = useState([]);

  const [categoriesDetails, setCategoriesDetails] = useState([]);

  const history = useHistory();

  // useEffect(() => {
  //   axios.get(`${logigloUrl}/forumCategoriesWithStats`).then((response) => {
  //     setCategories(response.data);
  //   });
  // }, []);

  useEffect(() => {
    axios.get(`${logigloUrl}/forumCategoriesWithAllStats`).then((response) => {
      setCategoriesDetails(response.data);
    });
  }, []);



  const [selectedOptions, setSelectedOptions] = useState({
    transport_mode: null,
    postType: null,
    service: null,
    postOrigin: null,
    shipment: null,
  });

  const handleOptionChange = (categoryName, selectedOption) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [categoryName]: selectedOption,
    }));
  };

  const handleClick = (category, option) => {
    
    setSelectedOptions((prev) => ({
      ...prev,
      [category]: option,
    }));
    history.push(`/forumDashboard/category/${category}/${option}`);
    // console.log(`Selected ${category}:`, option);
  };

  const getTimeDisplayText = (postCreatedAt) => {
    const createdTime = parseISO(postCreatedAt);
    const indianTimeZone = "Asia/Kolkata"; // India time zone
    const currentTime = new Date();

    // Convert createdTime to Indian time zone
    const createdTimeInIndianTimeZone = utcToZonedTime(
      createdTime,
      indianTimeZone
    );

    const timeDifferenceInSeconds = differenceInSeconds(
      currentTime,
      createdTimeInIndianTimeZone
    );
    const timeDifferenceInMinutes = differenceInMinutes(
      currentTime,
      createdTimeInIndianTimeZone
    );
    const timeDifferenceInHours = differenceInHours(
      currentTime,
      createdTimeInIndianTimeZone
    );
    const timeDifferenceInDays = differenceInDays(
      currentTime,
      createdTimeInIndianTimeZone
    );

    if (timeDifferenceInSeconds < 60) {
      // If post is created within the last minute, display "X seconds ago"
      return `${timeDifferenceInSeconds} second${
        timeDifferenceInSeconds !== 1 ? "s" : ""
      } ago`;
    } else if (timeDifferenceInMinutes < 60) {
      // If post is created within the last hour, display "X minutes ago"
      return `${timeDifferenceInMinutes} minute${
        timeDifferenceInMinutes !== 1 ? "s" : ""
      } ago`;
    } else if (timeDifferenceInHours < 24) {
      // If post is created within the last day, display "X hours and Y minutes ago"
      const remainingMinutes =
        timeDifferenceInMinutes - timeDifferenceInHours * 60;
      return `${timeDifferenceInHours} hour${
        timeDifferenceInHours !== 1 ? "s" : ""
      } ${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""} ago`;
    } else {
      // If post is created more than 1 day ago, display formatted date and time
      return format(createdTimeInIndianTimeZone, "MM/dd/yyyy, hh:mm:ss a");
    }
  };

  // const onClickRecentPost = (category) => {
  //   console.log(category, "category")
  // }

  const post_title_to_display = 120; // Number of letters to display
  const getFirstNLettersForPostTitle = (text, n) => {
    const firstNLetters = text.slice(0, n); // Extract the first N letters
    return firstNLetters + (text.length > n ? "..." : ""); // Add "..." if text is longer than N letters
  };

  // console.log(categories, "categorycategory")

  return (
    <div className="categories-main-comp">
      {/* user Stats */}
      <UserStatistics />

      {/* Forum Categories Dashboar Old UI */}
      {/* <div className="categories-inner-comp">
        <h4 className="category-header">Posts for Different Categories</h4>
        {categories.map((category, displayIndex) => (
          <div
            key={category.id}
            className={`category-container ${
              displayIndex % 2 === 0
                ? "each-category-container-odd"
                : "each-category-container-even"
            }`}
          >
            <h4 className="category-sub-header">{category.name}</h4>
            <div className="category-details-con">
              <div >
                {category.options.map((option) => (
                  <div key={option.id}>
                    <ul className="category-items-list">
                      <li
                      className="category-list-sty"
                        key={category.id}
                        onClick={() =>
                          handleClick(
                            category.name === "Quote Enquiry"
                              ? "transport_mode"
                              : category.name === "General Enquiry"
                              ? "transport_mode"
                              : category.name === "General Discussion"
                              ? "general_discussion"
                              : category.name === "Supply Chain Management"
                              ? "supply_chain_management"
                              : category.name === "Industry Verticals"
                              ? "Industry_verticals"
                              : category.name === "Technologies & Innovations"
                              ? "technologies_Innovations"
                              : category.name === "Sustainability & CSR"
                              ? "sustainability_CSR"
                              : category.name === "Regulations & Compliance"
                              ? "regulations_compliance"
                              : category.name === "Professional Development"
                              ? "professional_development"
                              : category.name,
                            option.label
                          )
                        }
                      >
                        {option.label}
                      </li>
                    </ul>
                  </div>
                ))}
              </div>

              <div className="stat-main-con">
                <div className="posts-replies-summary-con">
                <div className="each-stat">
                  <h7>{category.number_of_posts}</h7>
                  <h7 style={{color: '#707070'}}>Posts</h7>
                </div>

                <div className="each-stat">
                  <h7 >{category.number_of_replies}</h7>
                  <h7 style={{color: '#707070'}}>Replies</h7>
                </div>
                </div>

                <div className="post-stat-details-con">
                  
                  {category.posts.length > 0 && (
                    <div className="post-stat-inner-con">
                     
                      {category.posts.length > 0 && (
                        <div
                          key={category.posts[category.posts.length - 1].title}
                        >
                          <p className="category-summary-text">Recent Post:</p>
                        
                          <p className="category-items-list category-summary-text" 
                          style={{cursor: "pointer"}}
                         onClick={() =>
                          handleClick(
                            category.name === "Quote Enquiry"
                              ? "transport_mode"
                              : category.name === "General Enquiry"
                              ? "transport_mode"
                              : category.name === "General Discussion"
                              ? "general_discussion"
                              : category.name === "Supply Chain Management"
                              ? "supply_chain_management"
                              : category.name === "Industry Verticals"
                              ? "Industry_verticals"
                              : category.name === "Technologies & Innovations"
                              ? "technologies_Innovations"
                              : category.name === "Sustainability & CSR"
                              ? "sustainability_CSR"
                              : category.name === "Regulations & Compliance"
                              ? "regulations_compliance"
                              : category.name === "Professional Development"
                              ? "professional_development"
                              : category.name,
                              category.posts[category.posts.length - 1].sub_category // Use the first option's label or modify as needed
                          )
                        }
                          >
                            Title:{" "}
                            {category.posts[category.posts.length - 1].title
                              .length > 120
                              ? `${category.posts[
                                  category.posts.length - 1
                                ].title.substring(0, 120)}...`
                              : category.posts[category.posts.length - 1].title}
                          </p>
                          <div className="created-by-details">
                          <p className="category-summary-text">
                            Posted by:{" "}
                            {
                              category.posts[category.posts.length - 1]
                                .created_by
                            }{" "}
                           
                          </p>
                          <p className = "posted-by-info" >
                              {getTimeDisplayText(
                                category.posts[category.posts.length - 1]
                                  .created_at
                              )}
                            </p>
                            </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div> */}


      <div className="discussion-board">
      <h4 className="category-header">Posts for Different Categories</h4>
      {categoriesDetails.map((category, displayIndex) => (
        <div key={category.id} className={`category-container ${
          displayIndex % 2 === 0
            ? "each-category-container-odd"
            : "each-category-container-even"
        }`}>
          <div className="category-name-con" style={{ backgroundColor: '#f4d99f'}}>
          <h4 className="category-name" >{category.name}</h4>
          <div className="each-category-summary-container"> <strong> {category.number_of_posts} Posts | {category.number_of_replies} Replies </strong></div>
        </div>

       
          {/* Render subcategories */}
          {category.options.map((option) => (
            option.enabled && (
              <div className="sub-cat-main-con">
              <div key={option.id} className="subcategory">
                <h6 className="subcategory-label-text" 
                 onClick={() =>
                  handleClick(
                    category.name === "Quote Enquiry"
                      ? "transport_mode"
                      : category.name === "General Enquiry"
                      ? "transport_mode"
                      : category.name === "General Discussion"
                      ? "general_discussion"
                      : category.name === "Supply Chain Management"
                      ? "supply_chain_management"
                      : category.name === "Industry Verticals"
                      ? "Industry_verticals"
                      : category.name === "Technologies & Innovations"
                      ? "technologies_Innovations"
                      : category.name === "Sustainability & CSR"
                      ? "sustainability_CSR"
                      : category.name === "Regulations & Compliance"
                      ? "regulations_compliance"
                      : category.name === "Professional Development"
                      ? "professional_development"
                      : category.name,
                    option.label
                  )
                }
                >{option.label}</h6>
                
                {/* Render posts under subcategory */}
                {option.posts.length > 0 ? (
                  <div className="sub-cat-post-info">
                    {option.posts.map((post, postIndex) => (
                      <div key={postIndex} >
                        <p className="category-summary-text">Recent Post:</p>
                        <p className="category-summary-text" style={{cursor: 'pointer'}}
                        onClick={() =>
                          handleClick(
                            category.name === "Quote Enquiry"
                              ? "transport_mode"
                              : category.name === "General Enquiry"
                              ? "transport_mode"
                              : category.name === "General Discussion"
                              ? "general_discussion"
                              : category.name === "Supply Chain Management"
                              ? "supply_chain_management"
                              : category.name === "Industry Verticals"
                              ? "Industry_verticals"
                              : category.name === "Technologies & Innovations"
                              ? "technologies_Innovations"
                              : category.name === "Sustainability & CSR"
                              ? "sustainability_CSR"
                              : category.name === "Regulations & Compliance"
                              ? "regulations_compliance"
                              : category.name === "Professional Development"
                              ? "professional_development"
                              : category.name,
                            option.label
                          )
                        } 
                        > {getFirstNLettersForPostTitle(
                          post.title,
                          post_title_to_display
                        )} </p>
                        <div className="created-by-details">
                        <p >Posted by: {post.created_by}</p>
                        <p className="posted-on-text-styles">

                        {getTimeDisplayText(
                                post.created_at
                              )}

                        </p>
                        </div>
                      </div>
                    ))}
                  
                  </div>
                ) : (
                  <div className="no-post-text">No Posts Available in this Sub Category</div>
                )}
              </div>
              
              <hr style={{margin: '0px'}} />
              </div>
            )
          ))}
        </div>
        
      ))}
    </div>
   
    
    </div>
  );
};

export default Categories;
